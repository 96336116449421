<template>
  <svg aria-label="menuBurger" width="40" height="24" viewBox="0 0 40 24" fill="currentColor" opacity="1" xmlns="http://www.w3.org/2000/svg">
  <rect aria-label="menuBurger" x="0" y="0" width="32" height="6" rx="5"></rect>
  <rect aria-label="menuBurger" x="0" y="7" width="32" height="6" rx="5"></rect>
  <rect aria-label="menuBurger" x="0" y="14" width="32" height="6" rx="5"></rect>
</svg>
</template>

<script>
export default {
  name: "EosIconsMenuBurger",
};
</script>
